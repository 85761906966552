html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .variety-container > div > .MuiCardContent-root { */
/*   height: 100vh; */
/*   overflow: hidden; */


/* .selection-grid .MuiPaper-root { */
/*   padding: 8px !important; */
/* } */

/* header > div { */
/*   padding-right: 0 !important; */
/* } */

/* header { */
/*   padding: 0 !important; */
/* } */

/* #root .MuiGrid2-root:first-of-type > .MuiGrid2-root{ */
/*   padding: 0 !important; */
/* } */

/* .list-container ul { */
/*   margin-bottom: 64px !important; */
/* } */

/* ul.recharts-default-legend { */
/*   display: flex; */
/*   justify-content: left; */
/* } */

/* ul.recharts-default-legend > li { */
/*   margin-right: 2px !important; */
/* } */

/* ul.recharts-default-legend > li > svg { */
/*   margin-right: 2px !important; */
/* } */

/* footer { */
/*   background: linear-gradient(180deg, #f8bbd0, #f48fb1, #f06292, #ec407a, #e91e63, #d81b60, #c2185b, #ad1457, #880e4f) !important; */
/* } */

/* header { */
/*   background: linear-gradient(150deg, #90caf9, #64b5f6, #2196f3, #1e88e5, #1976d2, #1565c0, #0d47a1) !important; */
/* } */

/* .custom-fab { */
/*   background: linear-gradient(150deg, #90caf9, #64b5f6, #2196f3, #1e88e5, #1976d2, #1565c0, #0d47a1) !important;   */
/* } */

/* .MuiChip-filled, .MuiAvatar-root { */
/*   background: linear-gradient(150deg, #90caf9, #64b5f6, #2196f3, #1e88e5, #1976d2, #1565c0, #0d47a1) !important; */
/* } */

/* .MuiChip-outlined { */
/*   box-shdow: inset 0px 8px 8px 0 #90caf9; */
/* } */

.filter-container h6 {
  padding-left: 8px
}
